import React, { useState, useEffect } from 'react';

import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, SaveButton, useNotify, useRefresh, useUpdate, CheckboxGroupInput, useGetList } from 'react-admin';

import { QueryParamConstant } from '../../../constants/common.constants';

const InvestPostClosingOffersModal = ({
  element = {},
  openModal = false,
  modalClosed = function () {},
  onSubmit = null,
}: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { error }] = useUpdate();

  const [open, setOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [offerChoices, setOfferChoices] = useState([]);

  const {
    data: offers,
    isLoading: offersLoading,
    error: offerError,
  }: { data: any; isLoading: boolean; error: any } = useGetList('offers', {
    pagination: { perPage: QueryParamConstant.SKIP_PAGINATION, page: QueryParamConstant.SKIP_PAGINATION },
  });

  const {
    data: offerStatusList,
    isLoading: isOfferStatusLoading,
    error: offerStatusError,
  }: { data: any; isLoading: boolean; error: any } = useGetList('offer-options');

  const handleClose = () => {
    setOpen(false);
    modalClosed();
  };

  const handleSubmit = (dataToUpdate: any) => {
    if (onSubmit) return onSubmit(dataToUpdate);

    setBtnDisabled(true);
    update(
      'users.investPostClosingOffers',
      { id: element?.id, data: { offerIds: dataToUpdate.postCloseOffers } },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          handleClose();
          notify('Offer is added to the list successfully', { type: 'success', undoable: true });
          setBtnDisabled(false);
        },
        onSettled: () => {
          refresh();
        },
      },
    );

    if (error) return error;
  };

  useEffect(() => {
    if (offers && offerStatusList) {
      const closedOffers = offers?.filter((anOffer: any) => {
        const offerStatus = offerStatusList?.find((anOfferStatus: any) => anOfferStatus.id === anOffer.statusId);

        if (offerStatus?.name === 'Closed') {
          return true;
        }

        return false;
      });
      setOfferChoices(closedOffers);
    }
  }, [offers, offerStatusList]);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  if (isOfferStatusLoading || offersLoading) {
    return <CircularProgress />;
  }

  if (offerStatusError) {
    notify(JSON.stringify(offerStatusError), { type: 'error' });

    return <p>Unknown error occured while fetching offer status list</p>;
  }

  if (offerError) {
    notify(JSON.stringify(offerError), { type: 'error' });

    return <p> Unknown error occurred while fetching offers list</p>;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Post Offer Closing Investment</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit} defaultValues={element}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CheckboxGroupInput
              source='postCloseOffers'
              label='Closed Offers List'
              choices={offerChoices as any[]}
              row={false}
            />
            <DialogActions>
              <Button variant='outlined' color='inherit' onClick={handleClose}>
                Close
              </Button>
              <SaveButton disabled={btnDisabled} label={btnDisabled ? 'Loading' : 'Save'} />
            </DialogActions>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default InvestPostClosingOffersModal;
