import { useState } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Button } from 'react-admin';

import Styles from '../../../constants/styles';
import { UserDto } from '../../../dtos/users.dtos';
import UserActions from './UserActions';

const UserActionsBase = ({ element }: { element: UserDto }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        aria-haspopup='listbox'
        aria-controls='lock-menu'
        onClick={e => {
          handleClickListItem(e);
        }}
        style={Styles.user.moreActionsButton}>
        <>
          USER ACTIONS
          <KeyboardArrowDown fontSize='small' />
        </>
      </Button>
      <UserActions element={element} onClose={handleClose} anchorElement={anchorEl} />
    </>
  );
};
export default UserActionsBase;
