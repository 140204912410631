import { format, isDate } from 'date-fns';
import _ from 'lodash';
import { GetManyParams, GetOneParams, UpdateParams, UpdateResult } from 'react-admin';
import { DateConstant, QueryParamConstant } from 'src/constants';
import { getClientApi } from 'src/data-communication';
import { OfferCategoryNameDto, OfferDetailsDto, OfferDto, OfferOrderStatusUpdateEmailFieldDto } from 'src/dtos';
import { OfferExternalSecurityDeliveryOptionView } from 'src/models';
import { areCheckInstructionsEmpty, paginate } from 'src/utils';

import { createOfferDocument } from './offerDocuments.providers';

const clientApi = getClientApi();

const isClosed = (offer: any) => offer._statusName === 'Closed';

const calculateFunnelSummation = (offer: any) => {
  if (offer?._ordersSummation?.useCustom) return Number(offer._ordersSummation.custom || 0).toString();

  const summationStatus = offer._ordersSummation
    ?.filter((item: any) => item?._includeInSummation)
    ?.map((item: any) => item?.status);

  return summationStatus?.join(',') || null;
};

const calculateTotalCapitalRaised = (offer: any): number | null => {
  if (!offer._ordersSummation) {
    return null;
  }

  if (offer._ordersSummation?.useCustom) {
    return Number(offer._ordersSummation.custom || 0);
  }

  const totalRaised = offer._ordersSummation?.reduce((total: number, item: any) => {
    if (item?._includeInSummation) {
      return total + item.sumRaisedInvestment;
    }

    return total;
  }, 0);

  return totalRaised;
};

const proccessImages = async (newOffer: any, identifier: string, assets: any[], logoUrl: any) => {
  let logoUrlBefore;

  if (Boolean(logoUrl?.rawFile)) {
    logoUrlBefore = await clientApi.offerDocuments.upload({
      body: {
        rawFile: logoUrl.rawFile,
        filename: `offers/${identifier}/logo-${logoUrl?.title}`,
      },
    });
  } else {
    logoUrlBefore = Boolean(logoUrl) ? logoUrl : undefined;
  }
  newOffer.logoUrl = logoUrlBefore;
  const indexedAssets = assets.map((asset, index) => ({ ...asset, index }));
  const filteredAssetToProccessImage = indexedAssets.filter(asset => asset?.previewImageUrl?.rawFile);

  const assetsURLs = filteredAssetToProccessImage?.length
    ? await Promise.all(
        filteredAssetToProccessImage?.map(({ previewImageUrl }: any) => {
          return clientApi.offerDocuments.upload({
            body: {
              rawFile: previewImageUrl?.rawFile,
              filename: `offers/${identifier}/asset-${previewImageUrl?.title?.replace(/ /g, '-')}`,
            },
          });
        }),
      )
    : [];

  newOffer.assets = assets.map(({ type, url, previewImageUrl, isVideo }, index) => {
    const foundIndex = filteredAssetToProccessImage.findIndex(filteredAsset => filteredAsset.index === index);
    const assetURL = foundIndex > -1 ? assetsURLs[foundIndex] : previewImageUrl;
    if (isVideo)
      return {
        type: 'video',
        url,
        previewImageUrl: assetURL,
      };

    return {
      type: type || previewImageUrl?.rawFile?.type || 'image/*',
      url: assetURL,
      previewImageUrl: undefined,
    };
  });

  return newOffer;
};

const filterOffers = (data: OfferDto[], filter?: { [key: string]: string }): OfferDto[] => {
  let filteredOffers: OfferDto[] = filter?.name
    ? data.filter((offer: OfferDto) => offer.name?.toLowerCase().indexOf(filter?.name?.toLowerCase()) > -1)
    : [...data];

  const otherFilters: { [key: string]: string } = _.omit(filter, ['name']);
  filteredOffers = otherFilters ? (_.filter(filteredOffers, otherFilters) as OfferDto[]) : [...filteredOffers];

  return filteredOffers;
};

function assertIsDate(value: Date | string): asserts value is Date {
  const isValid = isDate(value);

  if (!isValid) {
    throw new Error('Invalid date');
  }
}

const stripTimeFromDateIfFound = (value: Date | string) => {
  try {
    assertIsDate(value);

    return format(value, DateConstant.DEFAULT_FORMAT);
  } catch {
    return value;
  }
};

export const getOfferList = async (params: any) => {
  const { pagination, sort } = params;

  const queryParams = {
    skip: pagination?.perPage && pagination.perPage * (pagination.page - 1),
    take: pagination?.perPage,
    sortBy: sort?.field,
    sortDirection: sort?.order.toLowerCase(),
  };

  const data = await clientApi.offers.list();
  const filteredOffers = filterOffers(data, params.filter);
  const sortDirection: 'asc' | 'desc' = queryParams.sortDirection?.toLowerCase() as 'asc' | 'desc';
  const listOffersFiltered = _.orderBy(filteredOffers, [queryParams.sortBy], [sortDirection]);
  let paginatedOffers = listOffersFiltered;

  if (pagination?.perPage !== QueryParamConstant.SKIP_PAGINATION) {
    paginatedOffers =
      pagination?.perPage && pagination?.page
        ? paginate(listOffersFiltered, params.pagination.perPage, params.pagination.page)
        : listOffersFiltered;
  }

  return {
    data: paginatedOffers,
    total: filteredOffers?.length || 0,
  };
};

export const getManyOffers = async (params: GetManyParams) => {
  const data = await clientApi.offers.list();
  const filteredOffers: OfferDto[] = data.filter((offer: OfferDto) => params.ids?.includes(offer.id));

  return {
    data: filteredOffers,
    total: filteredOffers.length,
  };
};

export const getOffer = async (params: GetOneParams) => {
  const data = await clientApi.offers.retrieve({ params });

  const assetsTemp =
    data?.assets?.map((asset: any) => ({
      type: asset.type,
      isVideo: asset?.previewImageUrl && asset.previewImageUrl !== '' ? true : false,
      url: asset?.previewImageUrl && asset.previewImageUrl !== '' ? asset.url : null,
      previewImageUrl: asset?.previewImageUrl && asset.previewImageUrl !== '' ? asset?.previewImageUrl : asset.url,
    })) || [];

  return {
    data: { ...data, _assets_temp: assetsTemp },
  };
};

export const createOffer = async (params: any) => {
  let newOffer = _.omit(params?.data, [
    '_logo_url_temp',
    '_assets_temp',
    '_documents_temp',
    '_ordersSummation',
    '_categoryName',
    '_statusName',
    'cardPaymentAccount',
  ]);
  newOffer.assets = [];
  newOffer.anticipatedStartDate =
    newOffer?.anticipatedStartDate && newOffer.anticipatedStartDate !== '' ? newOffer.anticipatedStartDate : null;
  newOffer.shortUrlRoute = newOffer?.shortUrlRoute && newOffer.shortUrlRoute !== '' ? newOffer.shortUrlRoute : null;
  newOffer.closeDate = newOffer?.closeDate && newOffer.closeDate !== '' ? newOffer.closeDate : null;
  newOffer.recurring = newOffer?.closeDates?.length > 1;

  if (params.data.allowCardPayments) {
    newOffer.cardPaymentAccountType = params.data.cardPaymentAccount.type;
  }

  if (newOffer?.closeDates.length) {
    newOffer.closeDates = newOffer.closeDates.map((aCloseDate: Date) =>
      format(aCloseDate, DateConstant.DEFAULT_FORMAT),
    );
  }

  newOffer.subtextOnClosed =
    newOffer?.subtextOnClosed && newOffer.subtextOnClosed !== '' ? newOffer.subtextOnClosed : null;

  newOffer.maximumInvestCurrencyAmt =
    newOffer?.maximumInvestCurrencyAmt && newOffer.maximumInvestCurrencyAmt !== ''
      ? newOffer.maximumInvestCurrencyAmt
      : null;

  if (params.data._categoryName !== OfferCategoryNameDto.Conditional) {
    newOffer.minSharePrice = null;
    newOffer.maxSharePrice = null;
  }

  if (params.data.orderStatusUpdateEmailFields?.length > 0) {
    newOffer.orderStatusUpdateEmailFields = params.data.orderStatusUpdateEmailFields.map(
      (anOption: OfferOrderStatusUpdateEmailFieldDto) => ({
        status: anOption.status,
        line: anOption.line,
        footer: anOption.footer === '' ? undefined : anOption.footer,
      }),
    );
  }

  if (params.data.isExternalOffer) {
    if (areCheckInstructionsEmpty(params.data.externalDetails.checkInstructions)) {
      newOffer.externalDetails.checkInstructions = undefined;
    }

    if (!params.data.externalDetails?.disclaimer) {
      newOffer.externalDetails.disclaimer = undefined;
    }

    if (!params.data.externalDetails?.completedOfferOrderDisclaimer) {
      newOffer.externalDetails.completedOfferOrderDisclaimer = undefined;
    }

    if (params.data.externalDetails?.securityDeliveryOptions?.length > 0) {
      newOffer.externalDetails.securityDeliveryOptions = params.data.externalDetails?.securityDeliveryOptions.map(
        (anOption: OfferExternalSecurityDeliveryOptionView) => ({
          label: anOption.label,
          tooltip: anOption.tooltip === '' ? undefined : anOption.tooltip,
          hasTooltip: anOption.tooltip === '' ? false : anOption.hasTooltip,
          isReadOnly: anOption.isReadOnly === '' ? false : anOption.isReadOnly,
          initialValue: anOption.initialValue === '' ? false : anOption.initialValue,
        }),
      );
    }

    if (!params.data.externalDetails?.transferAgentName) {
      newOffer.externalDetails.transferAgentName = undefined;
    }

    if (!params.data.externalDetails?.bankName) {
      newOffer.externalDetails.bankName = undefined;
    }

    if (!params.data.externalDetails?.finalCircularUrl) {
      newOffer.externalDetails.finalCircularUrl = undefined;
    }
  } else {
    newOffer.externalDetails = undefined;
  }

  const offerData = await clientApi.offers.create({ body: newOffer as any });
  const { id } = offerData;

  let updatedData = { data: { ...offerData } };

  try {
    updatedData = await patchOffer({ data: { ...params?.data, id } });
  } catch (e: any) {
    throw new Error(`Offer was created, but an error occurred while updating assets: ${e.message}`);
  }

  const documents = [...params?.data?._documents_temp];

  let createdDocuments = [];

  try {
    createdDocuments = documents?.length
      ? await Promise.all(
          documents.map(document => {
            return createOfferDocument({ data: { ...document, offerId: id } });
          }),
        ).then(createdDocs => createdDocs.map(({ data }: any) => data))
      : [];
  } catch (e: any) {
    throw new Error(`Offer was created, but an error occurred while updating documents list: ${e.message}`);
  }

  return {
    data: { ...updatedData, id, documents: createdDocuments },
  };
};

export const patchOffer = async (params: any) => {
  const offer = params.data;
  let updateOffer = _.omit(offer, [
    'id',
    'isComingSoon',
    '_assets_temp',
    '_documents_temp',
    '_logo_url_temp',
    '_categoryName',
    '_ordersSummation',
    'documents',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    '_statusName',
    'cardPaymentAccount',
  ]);

  try {
    updateOffer = await proccessImages(
      updateOffer,
      offer.id,
      params?.data?._assets_temp ? params.data._assets_temp : [],
      params.data._logo_url_temp,
    );
    updateOffer.videoUrl = null;
    updateOffer.videoThumbnailUrl = null;
    updateOffer.logoUrl = updateOffer?.logoUrl && updateOffer.logoUrl !== '' ? updateOffer.logoUrl : null;
    updateOffer.anticipatedStartDate =
      updateOffer?.anticipatedStartDate && updateOffer.anticipatedStartDate !== ''
        ? updateOffer.anticipatedStartDate
        : null;
    updateOffer.shortUrlRoute =
      updateOffer?.shortUrlRoute && updateOffer.shortUrlRoute !== '' ? updateOffer.shortUrlRoute : null;
    updateOffer.closeDate = updateOffer?.closeDate && updateOffer.closeDate !== '' ? updateOffer.closeDate : null;
    updateOffer.recurring = updateOffer?.closeDates?.length > 1;
    updateOffer.subtextOnClosed =
      updateOffer?.subtextOnClosed && updateOffer.subtextOnClosed !== '' ? updateOffer.subtextOnClosed : null;
    updateOffer.maximumInvestCurrencyAmt =
      updateOffer?.maximumInvestCurrencyAmt && updateOffer.maximumInvestCurrencyAmt !== ''
        ? updateOffer.maximumInvestCurrencyAmt
        : null;
    updateOffer.ordersSummation = isClosed(offer) ? calculateFunnelSummation(offer) : null;
    updateOffer.totalCapitalRaised = isClosed(offer) ? calculateTotalCapitalRaised(offer) : null;

    if (updateOffer?.closeDates.length) {
      updateOffer.closeDates = updateOffer.closeDates.map((aCloseDate: Date | string) =>
        stripTimeFromDateIfFound(aCloseDate),
      );
    }

    if (params.data.allowCardPayments) {
      updateOffer.cardPaymentAccountType = params.data.cardPaymentAccount.type;
    }

    if (params.data?.orderStatusUpdateEmailFields?.length > 0) {
      updateOffer.orderStatusUpdateEmailFields = params.data.orderStatusUpdateEmailFields.map(
        (anOption: OfferOrderStatusUpdateEmailFieldDto) => ({
          status: anOption.status,
          line: anOption.line,
          footer: anOption.footer === '' ? null : anOption.footer,
        }),
      );
    }

    if (params.data.isExternalOffer) {
      if (areCheckInstructionsEmpty(params.data.externalDetails.checkInstructions)) {
        updateOffer.externalDetails.checkInstructions = null;
      }

      if (!params.data.externalDetails?.disclaimer) {
        updateOffer.externalDetails.disclaimer = null;
      }

      if (!params.data.externalDetails?.completedOfferOrderDisclaimer) {
        updateOffer.externalDetails.completedOfferOrderDisclaimer = null;
      }

      if (params.data.externalDetails?.securityDeliveryOptions?.length > 0) {
        updateOffer.externalDetails.securityDeliveryOptions = params.data.externalDetails?.securityDeliveryOptions.map(
          (anOption: OfferExternalSecurityDeliveryOptionView) => ({
            label: anOption.label,
            tooltip: anOption.tooltip === '' ? null : anOption.tooltip,
            hasTooltip: anOption.hasTooltip === '' ? false : anOption.hasTooltip,
            isReadOnly: anOption.isReadOnly === '' ? false : anOption.isReadOnly,
            initialValue: anOption.initialValue === '' ? false : anOption.initialValue,
          }),
        );
      }

      if (!params.data.externalDetails?.transferAgentName) {
        updateOffer.externalDetails.transferAgentName = null;
      }

      if (!params.data.externalDetails?.bankName) {
        updateOffer.externalDetails.bankName = null;
      }

      if (!params.data.externalDetails?.finalCircularUrl) {
        updateOffer.externalDetails.finalCircularUrl = null;
      }
    } else {
      updateOffer.externalDetails = null;
    }

    if (offer._categoryName !== OfferCategoryNameDto.Conditional) {
      updateOffer.minSharePrice = null;
      updateOffer.maxSharePrice = null;
    }
  } catch (e: any) {
    throw new Error(`Processing Image Problem: ${e.message}`);
  }

  let data: any = {};

  try {
    data = await clientApi.offers.patch({
      params: {
        id: offer.id,
      },
      body: updateOffer as any,
    });
  } catch (e: any) {
    throw new Error(`An Error occurred while updating offer: ${e.message}`);
  }

  return {
    data,
  };
};

export const completeOffer = async (params: UpdateParams): Promise<UpdateResult<OfferDetailsDto>> => {
  const { id } = params;

  const response = await clientApi.offers.complete({
    params: { id: id.toString() },
    body: {
      useEmailNotifier: true,
    },
  });

  return {
    data: response,
  };
};

export const newOfferCardPaymentAccountLink = async (params: UpdateParams): Promise<UpdateResult<OfferDetailsDto>> => {
  const response = await clientApi.offers.newCardPaymentAccountLink({
    params: {
      id: params.id.toString(),
    },
  });

  return {
    data: response,
  };
};
