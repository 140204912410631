import { OfferOrderStatusDto } from './offerOrders.dtos';

export enum OfferDocumentTypeEnum {
  OFFERING_DOCUMENT = 'offering_document',
  RISK_FACTORS = 'risk_factors',
  OFFERING_SUMMARY = 'offering_summary',
  INVESTOR_PRESENTATION = 'investor_presentation',
  SUBSCRIPTION_AGREEMENT = 'subscription_agreement',
  CIRCULAR_OFFERING_DOCUMENT = 'circular_offering_document',
}

export interface AssetDto {
  type: string;
  url: string;
  previewImageUrl?: string;
}

export interface BaseOfferDocumentDto {
  id: string;
  type: OfferDocumentTypeEnum;
  url: string;
}

export enum OfferCardPaymentAccountTypeDto {
  Standard = 'Standard',
  Custom = 'Custom',
}

export enum OfferCardPaymentAccountStatusDto {
  Restricted = 'Restricted',
  Pending = 'Pending',
  Enabled = 'Enabled',
  Complete = 'Complete',
  Rejected = 'Rejected',
  Unknown = 'Unknown',
}

export interface PublicOfferCardPaymentAccountDto {
  externalId: string;
  status: OfferCardPaymentAccountStatusDto;
  email?: string;
}

export interface OfferCardPaymentAccountDto extends PublicOfferCardPaymentAccountDto {
  linkUrl: string;
  type: OfferCardPaymentAccountTypeDto;
}

export interface OfferOrderStatusUpdateEmailFieldDto {
  status: OfferOrderStatusDto;
  line: string;
  footer?: string;
}

export interface OfferExternalWireInstructionsDto {
  bankName: string;
  abaNumber: string;
  accountNumber: string;
  accountName: string;
  attentionLine: string;
}

export interface OfferExternalCheckInstructionsDto {
  payableTo: string;
  bankName: string;
  address: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

export interface OfferExternalSecurityDeliveryOptionDto {
  hasTooltip: boolean;
  isReadOnly: boolean;
  label: string;
  initialValue: boolean;
  tooltip: string;
}

export interface OfferExternalDetailsDto {
  disclaimer?: string;
  completedOfferOrderDisclaimer?: string;
  wireInstructions: OfferExternalWireInstructionsDto;
  checkInstructions?: OfferExternalCheckInstructionsDto;
  securityDeliveryOptions?: OfferExternalSecurityDeliveryOptionDto[];
  transferAgentName?: string;
  bankName?: string;
  finalCircularUrl?: string;
}

export enum OfferCategoryNameDto {
  Conditional = 'Conditional',
}

export interface OfferDto {
  id: string;
  name: string;
  new: boolean;
  isComingSoon: boolean;
  closeDate: string | null;
  closeDates: string[];
  recurring: boolean;
  yield: string;
  statusId: string;
  offerTypeId: string;
  industryId: string;
  categoryId: string;
  securityTypeId: string;
  logoUrl: string | null;
  videoThumbnailUrl: string | null;
  shortUrlRoute: string | null;
  visibleToEndUsers: boolean;
  sector: string;
  sortOrder: number;
  featured: boolean;
  startDate: string | null;
}

export interface OfferDetailsDto {
  id: string;
  name: string;
  isComingSoon: boolean;
  pricePerShare: number;
  minimumInvestCurrencyAmt: number;
  maximumInvestCurrencyAmt: number | null;
  minimumInvestIncCurrencyAmt: number;
  minimumInvestShareQty: number;
  maximumInvestShareQty: number;
  minimumInvestShareIncrQty: number;
  minTotalRaiseCurrencyAmt: number;
  maxTotalRaiseCurrencyAmt: number;
  ordersSummation: string | null;
  totalCapitalRaised?: number;
  yield: string;
  yieldDescription: string;
  startDate: string | null;
  closeDate: string | null;
  closeDates: string[];
  recurring: boolean;
  sector: string;
  sortOrder: number;
  featured: boolean;
  new: boolean;
  allowRestrictedPersons: boolean;
  visibleToEndUsers: boolean;
  isExternalOffer: boolean;
  description: string;
  disclaimer: string;
  videoThumbnailUrl: string | null;
  videoUrl: string | null;
  logoUrl: string | null;
  assets: string[];
  nonVisibleAppFields: string[];
  subtextOnClosed?: string;
  exitDescription: string;
  anticipatedStartDate: string | null;
  shortUrlRoute: string | null;
  investmentRanges: string[];
  statusId: string;
  offerTypeId: string;
  industryId: string;
  categoryId: string;
  securityTypeId: string;
  subscriptionAgreementId?: string;
  privateQuoteId?: string;
  documents: BaseOfferDocumentDto[];
  createdAt: string | null;
  updatedAt: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  allowAchTransfers: boolean;
  allowWireTransfers: boolean;
  allowCheckTransfers: boolean;
  allowCardPayments: boolean;
  cardPaymentAccount?: OfferCardPaymentAccountDto;
  externalDetails?: OfferExternalDetailsDto;
  orderStatusUpdateEmailFields?: OfferOrderStatusUpdateEmailFieldDto[];
}

export interface OfferAssetDto {
  url: string;
  type: string;
  previewImageUrl?: string;
}

interface BaseOfferDto {
  id: string;
  name: string;
  new: boolean;
  isComingSoon: boolean;
  closeDate: string | null;
  closeDates: string[];
  recurring: boolean;
  yield: string;
  statusId: string;
  offerTypeId: string;
  industryId: string;
  categoryId: string;
  securityTypeId: string;
  logoUrl: string | null;
  videoThumbnailUrl: string | null;
  shortUrlRoute: string | null;
}

export interface PublicOfferDto extends BaseOfferDto {
  assets: OfferAssetDto[];
  featured: boolean;
  ordersSummation?: string | null;
  allowAchTransfers: boolean;
  allowWireTransfers: boolean;
  allowCheckTransfers: boolean;
  allowCardPayments: boolean;
  minimumInvestCurrencyAmt: number;
  totalCapitalRaised?: number;
  status: string;
  category: string;
  industry: string;
  type: string;
  security: string;
}

export interface PublicOfferDetailsDto extends PublicOfferDto {
  isExternalOffer: boolean;
  description: string;
  disclaimer: string;
  yieldDescription: string;
  exitDescription: string;
  subtextOnClosed?: string;
  pricePerShare: number;
  maximumInvestCurrencyAmt: number | null;
  minTotalRaiseCurrencyAmt: number;
  maxTotalRaiseCurrencyAmt: number;
  minSharePrice?: number;
  maxSharePrice?: number;
  startDate: string | null;
  ordersSummation: string | null;
  allowRestrictedPersons: boolean;
  sector: string;
  videoUrl: string | null;
  documents: BaseOfferDocumentDto[];
  anticipatedStartDate: string | null;
  shortUrlRoute: string | null;
  investmentRanges: string[];
  nonVisibleAppFields?: string[] | null;
  cardPaymentAccount?: PublicOfferCardPaymentAccountDto;
  externalDetails?: OfferExternalDetailsDto;
  subscriptionAgreementId?: string;
}

interface BaseOfferParamsDto {
  id: string;
}

export interface CreateOfferOrderStatusUpdateEmailFieldDto {
  status: OfferOrderStatusDto;
  line: string;
  footer?: string;
}

export interface CreateOfferExternalWireInstructionsDto {
  bankName: string;
  abaNumber: string;
  accountNumber: string;
  accountName: string;
  attentionLine: string;
}

export interface CreateOfferExternalCheckInstructionsDto {
  payableTo: string;
  bankName: string;
  address: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
}

export interface CreateOfferExternalSecurityDeliveryOptionDto {
  hasTooltip: boolean;
  isReadOnly: boolean;
  label: string;
  initialValue: boolean;
  tooltip?: string;
}

export interface CreateOfferExternalDetailsDto {
  disclaimer?: string;
  completedOfferOrderDisclaimer?: string;
  wireInstructions: CreateOfferExternalWireInstructionsDto;
  checkInstructions?: CreateOfferExternalCheckInstructionsDto;
  securityDeliveryOptions?: CreateOfferExternalSecurityDeliveryOptionDto[];
  transferAgentName?: string;
  bankName?: string;
  finalCircularUrl?: string;
}

export interface CreateOfferBodyDto {
  name: string;
  pricePerShare: number;
  minimumInvestCurrencyAmt: number;
  maximumInvestCurrencyAmt: number | null;
  minimumInvestIncCurrencyAmt: number;
  minimumInvestShareQty: number;
  maximumInvestShareQty: number;
  minimumInvestShareIncrQty: number;
  minTotalRaiseCurrencyAmt: number;
  maxTotalRaiseCurrencyAmt: number;
  minSharePrice: number | null;
  maxSharePrice: number | null;
  ordersSummation: string | null;
  yield: string;
  yieldDescription: string;
  startDate: string | null;
  closeDate: string | null;
  closeDates: string[];
  recurring: boolean;
  sector: string;
  sortOrder: number;
  featured: boolean;
  new: boolean;
  allowRestrictedPersons: boolean;
  visibleToEndUsers: boolean;
  isExternalOffer: boolean;
  description: string;
  disclaimer: string;
  videoThumbnailUrl: string | null;
  videoUrl: string | null;
  logoUrl: string | null;
  assets: string[];
  nonVisibleAppFields: string[];
  subtextOnClosed?: string;
  exitDescription: string;
  anticipatedStartDate: string | null;
  shortUrlRoute: string | null;
  investmentRanges: string[];
  statusId: string;
  offerTypeId: string;
  industryId: string;
  categoryId: string;
  securityTypeId: string;
  subscriptionAgreementId?: string;
  privateQuoteId?: string;
  allowAchTransfers: boolean;
  allowWireTransfers: boolean;
  allowCheckTransfers: boolean;
  allowCardPayments: boolean;
  cardPaymentAccountType?: OfferCardPaymentAccountTypeDto;
  externalDetails?: CreateOfferExternalDetailsDto;
  orderStatusUpdateEmailFields?: CreateOfferOrderStatusUpdateEmailFieldDto[];
}

export interface CreateOfferDto {
  body: CreateOfferBodyDto;
}

export interface GetPublicOfferDto {
  params: BaseOfferParamsDto;
}

export interface GetOfferDto {
  params: BaseOfferParamsDto;
}

export interface PatchOfferExternalDetailsDto {
  disclaimer?: string | null;
  wireInstructions?: OfferExternalWireInstructionsDto;
  checkInstructions?: OfferExternalCheckInstructionsDto | null;
  securityDeliveryOptions?: OfferExternalSecurityDeliveryOptionDto[] | null;
}

export type PatchOfferBodyDto = Partial<Omit<CreateOfferDto, 'externalDetails' | 'orderStatusUpdateEmailFields'>> & {
  id: string;
  totalCapitalRaised?: number;
  externalDetails?: PatchOfferExternalDetailsDto | null;
  orderStatusUpdateEmailFields?: CreateOfferOrderStatusUpdateEmailFieldDto[] | null;
};

export interface PatchOfferDto {
  params: BaseOfferParamsDto;
  body: PatchOfferBodyDto;
}

export interface CompleteOfferBodyDtO {
  useEmailNotifier?: boolean;
}

export interface CompleteOfferDto {
  params: BaseOfferParamsDto;
  body: CompleteOfferBodyDtO;
}

export interface NewOfferCardPaymentAccountLinkDto {
  params: BaseOfferParamsDto;
}
