import React, { useEffect, useState } from 'react';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import { MenuList } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNotify, useUpdate, useRefresh, Confirm } from 'react-admin';

import Colors from '../../../constants/colors';
import Styles from '../../../constants/styles';
import { ActionDropdownDto } from '../../../dtos/users.dtos';
import InvestPostClosingOffersModal from './InvestPostClosingOffersModal';

const UserActions = ({ element, anchorElement = null, onClose }: ActionDropdownDto) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { error, isLoading }] = useUpdate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(anchorElement);

  useEffect(() => {
    setAnchorEl(anchorElement);
    setIsOpen(true);
  }, [anchorElement]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
    onClose();
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [investPostClosingOffersModalOpen, setInvestPostClosingOffersModalOpen] = useState(false);

  const handleLockUser = () => {
    update(
      'users.lockUser',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User is locked successfully`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
      },
    );
    if (error) return error;
  };

  const handleUnlockUser = () => {
    update(
      'users.unlockUser',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User is unlocked successfully`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
      },
    );
    if (error) return error;
  };

  const handleCloseUser = () => {
    setConfirmOpen(true);
    handleClose();
  };

  const closeUser = () => {
    setConfirmOpen(false);

    update(
      'users.closeUser',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User is closed successfully`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
      },
    );
    if (error) return error;
  };

  const handleApproveUser = () => {
    update(
      'users.approveUser',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User is approved successfully`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
      },
    );
    if (error) return error;
  };

  const handleInvestPostClosingOffers = () => {
    setInvestPostClosingOffersModalOpen(true);
  };

  const onEnableMfa = () => {
    update(
      'users.enableMfa',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User MFA was successfully enabled.`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: error => {
          notify(`Error enabling user MFA. Received error: ${JSON.stringify(error)}`, {
            type: 'error',
            undoable: true,
          });
        },
      },
    );
  };

  const onDisableMfa = () => {
    update(
      'users.disableMfa',
      { id: element?.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`User MFA was successfully disabled.`, { type: 'success', undoable: true });
          handleClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: error => {
          notify(`Error disabling user MFA. Received error: ${JSON.stringify(error)}`, {
            type: 'error',
            undoable: true,
          });
        },
      },
    );
  };

  const getItems = () => {
    if (element?.isClosed)
      return (
        <MenuList>
          <MenuItem style={{ ...Styles.user.menuItem, opacity: 0.6 }}>
            <BlockIcon style={{ color: Colors.DANGER }} />
            <span style={{ color: Colors.DANGER, paddingLeft: 5 }}>THIS USER IS CLOSED</span>
          </MenuItem>
        </MenuList>
      );

    return (
      <MenuList>
        <MenuItem onClick={handleApproveUser} disabled={element?.isApproved}>
          <DoneIcon />
          <span>APPROVE USER ACCOUNT</span>
        </MenuItem>
        <MenuItem onClick={handleLockUser} disabled={element?.isLocked}>
          <LockIcon />
          <span>LOCK USER ACCOUNT</span>
        </MenuItem>
        <MenuItem onClick={handleUnlockUser} disabled={!element?.isLocked}>
          <LockOpenIcon />
          <span>UNLOCK USER ACCOUNT</span>
        </MenuItem>
        <MenuItem onClick={handleInvestPostClosingOffers}>
          <AddShoppingCartIcon />
          <span>ALLOW INVEST POST CLOSING</span>
        </MenuItem>
        <MenuItem onClick={handleCloseUser} disabled={element?.isClosed}>
          <BlockIcon />
          <span>CLOSE USER ACCOUNT</span>
        </MenuItem>
        <MenuItem onClick={onEnableMfa} disabled={element?.isMfaEnabled}>
          <MobileFriendlyIcon />
          <span style={{ paddingLeft: 5 }}>ENABLE USER MFA</span>
        </MenuItem>
        <MenuItem onClick={onDisableMfa} disabled={!element?.isMfaEnabled}>
          <MobileOffIcon />
          <span style={{ paddingLeft: 5 }}>DISABLE USER MFA</span>
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <>
      {anchorEl && (
        <Menu
          id='user-actions'
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}>
          {getItems()}
        </Menu>
      )}
      <Confirm
        isOpen={confirmOpen}
        loading={isLoading}
        title='Close user'
        content='This action is irreversible (user will never be able to login).'
        onConfirm={closeUser}
        onClose={() => setConfirmOpen(false)}
      />
      <InvestPostClosingOffersModal
        element={element}
        openModal={investPostClosingOffersModalOpen}
        modalClosed={() => {
          setInvestPostClosingOffersModalOpen(false);
        }}
      />
    </>
  );
};
export default UserActions;
